/**
 * /*!
 *
 * =========================================================
 * Argon Dashboard React - v1.0.0
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
 *
 * Coded by Creative Tim
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
//import "assets/scss/argon-dashboard-react.scss";
import "assets/css/argon-dashboard-react.css";
import "assets/css/cores.css";

//import AdminLayout from "layouts/Admin.jsx";
//import AuthLayout from "layouts/Auth.jsx";
import TagManager from "react-gtm-module";
import { get } from "utils/HttpUtil";
import Loading from "./components/Loading/Loading";

const configs = get("sys/configs");
const AdminLayout = lazy(() => import("layouts/Admin.jsx"));
const AuthLayout = lazy(() => import("layouts/Auth.jsx"));

configs.then((result) => {
  const tagManagerArgs = {
    gtmId: result.geral[0].idAnalitcs ? result.geral[0].idAnalitcs : "",
  };

  TagManager.initialize(tagManagerArgs);

  ReactDOM.render(
    <>
      <BrowserRouter>
        <Suspense fallback={<Loading loading={true} />}>
          <Switch>
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} configs={result} />}
            />
            <Route
              path="/auth"
              render={(props) => <AuthLayout {...props} configs={result} />}
            />
            <Route
              path="/load"
              render={(props) => <Loading loading={true} />}
            />
            <Redirect from="/" to="/auth/index" />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>,
    document.getElementById("root")
  );
});
