/** @format */

import axios from "axios";

import { apenasNumeros } from "./StrUtils";

var IDAPP;

try {
  IDAPP = sessionStorage.getItem("SISTEMA.APP_TOKEN");
} catch (error) {
  IDAPP = "0f403419-a464-4d60-9502-666785028290";
}

const HOST =
  document.location.host !== "localhost:3000" &&
  document.location.host !== "159.89.51.53"
    ? "https://" + document.location.host
    : "http://159.89.51.53";

export const urlBase = HOST;

export const instanceAxios = axios.create({
  //baseURL: 'http://159.89.51.53/api/v1/',
  baseURL: HOST + "/api/v1/",
  headers: {
    //'Authorization': sessionStorage.getItem('SISTEMA.APP_TOKEN') ? 'Bearer ' + sessionStorage.getItem('SISTEMA.APP_TOKEN') : 'Bearer ' + IDAPP,
    Authorization: "Bearer " + IDAPP,
    "Application-Authorization": IDAPP,
  },
  timeout: 600000,
});

export const put = async (servico, body, showLoading = false) => {
  try {
    return await instanceAxios.put(servico, body);
  } catch (error) {
    console.log("ERRRRRR ", error);
    return {};
  }
};

export const post = async (servico, body, showLoading = false) => {
  try {
    return await instanceAxios.post(servico, body);
  } catch (error) {
    console.log("ERRRRRR ", error);
    return {};
  }
};

export const del = async (servico, body, showLoading = false) => {
  return await instanceAxios.delete(servico, body);
};

export const get = async (servico, showLoading = false) => {
  let r = await instanceAxios.get(servico);
  return r.data;
};

export const recuperar = async () => {
  try {
    const r = await instanceAxios.post("auth/usuario/recuperar", {
      token: sessionStorage.getItem("PARLAMENTAR.APP_TOKEN"),
    });
    sessionStorage.setItem("PARLAMENTAR.APP_USUARIO", JSON.stringify(r.data));
    return r;
  } catch (error) {
    console.log("ERRRRRR ", error);
    return false;
  }
};

export const verificarToken = async () => {
  try {
    const r = await instanceAxios.post("auth/verificar", {
      token: sessionStorage.getItem("PARLAMENTAR.APP_TOKEN"),
    });

    return r;
  } catch (error) {
    return false;
  }
};

export const compartilhar = async (titulo, texto, url) => {
  if (navigator.share && typeof window.orientation !== "undefined") {
    navigator.share({
      title: titulo,
      text: "*" + titulo + "*\n\n" + texto + (url !== "" ? "\n\n" + url : ""),
    });
  } else {
    // Here we use the WhatsApp API as fallback; remember to encode your text for URI
    window.open(
      "https://api.whatsapp.com/send?text=" +
        encodeURIComponent(
          "*" + titulo + "*\n\n" + texto + (url !== "" ? "\n\n" + url : "")
        ),
      "_BLANK"
    );
  }
};

export const recarregar = (time, obj) => {
  const current = obj.props.location.pathname;
  setTimeout(() => {
    obj.props.history.replace(`/admin/reload`);
    obj.props.history.replace(current);
  }, time);
};

export const buscarEndeceoCep = async (cep) => {
  try {
    const cepStr = apenasNumeros(cep);
    const r = await instanceAxios.get("sys/endereco/cep/" + cepStr);
    return r.data;
  } catch (error) {
    return false;
  }
};
