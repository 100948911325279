import React from "react";

//import LoadingScreen from "react-loading-screen";
import "./layouts.css";

class Loading extends React.Component {
  render() {
    return this.props.loading === true ? (
      // <LoadingScreen
      //   loading={this.props.loading === true}
      //   bgColor="#FFF"
      //   spinnerColor="#FFFFFF"
      //   textColor="#AAA"
      //   logoSrc=""
      //   text=""
      //   children=""
      //
      <div class="ldg-screen">
        <div class="loader">
          <span class="box"></span>
          <span class="box"></span>
          <div class="code">
            <img src="../pc.png" width="120px" />
          </div>
          <span class="txt">CARREGANDO ...</span>
        </div>
      </div>
    ) : //</LoadingScreen>
    null;
  }
}

export default Loading;
