export const apenasNumeros = (str) => {
  if (str === undefined) {
    return "";
  }
  return str.replace(/\D/g, "");
};

export const dataBd = (data) => {
  return data.substr(6, 4) + "-" + data.substr(3, 2) + "-" + data.substr(0, 2);
};

export const dataBr = (data) => {
  return data.substr(8, 2) + "/" + data.substr(5, 2) + "/" + data.substr(0, 4);
};

export const formatDate = (data) => {
  try {
    const a = new Date(data.replace("00:00:00.000", "12:00:00.220"));
    return `${a.getDate().toString().length === 1 ? "0" : ""}${a.getDate()}/${
      (a.getMonth() + 1).toString().length === 1 ? "0" : ""
    }${a.getMonth() + 1}/${a.getFullYear()}`;
  } catch (error) {
    return data;
  }
};

export function moneyToFloat(number) {
  if (number && number !== "") {
    var valor = number.toString();
    if (valor.indexOf(",") > -1) {
      return parseFloat(number.toString().replace(/\./g, "").replace(",", "."));
    } else {
      return parseFloat(number);
    }
  }
  return 0.0;
}

export const formatTime = (data) => {
  try {
    const a = new Date(data.replace("00:00:00.000", "12:00:00.220"));
    return `${a.getHours().toString().length === 1 ? "0" : ""}${a.getHours()}:${
      a.getMinutes().toString().length === 1 ? "0" : ""
    }${a.getMinutes()}`;
  } catch (error) {
    return data;
  }
};

export const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "IMAGE_BUTTON",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export const getInit = (nome) => {
  let l = nome.split(" ");
  if (l.length > 1) {
    return l[0].substr(0, 1) + l[1].substr(0, 1);
  }
  return nome.substr(0, 2);
};

const convert = (d) => {
  // Converts the date in d to a date-object. The input can be:
  //   a date object: returned without modification
  //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
  //   a number     : Interpreted as number of milliseconds
  //                  since 1 Jan 1970 (a timestamp)
  //   a string     : Any format supported by the javascript engine, like
  //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
  //  an object     : Interpreted as an object with year, month and date
  //                  attributes.  **NOTE** month is 0-11.
  return d.constructor === Date
    ? d
    : d.constructor === Array
    ? new Date(d[0], d[1], d[2])
    : d.constructor === Number
    ? new Date(d)
    : d.constructor === String
    ? new Date(d)
    : typeof d === "object"
    ? new Date(d.year, d.month, d.date)
    : NaN;
};

export const compareDate = (a, b) => {
  // Compare two dates (could be of any type supported by the convert
  // function above) and returns:
  //  -1 : if a < b
  //   0 : if a = b
  //   1 : if a > b
  // NaN : if a or b is an illegal date
  // NOTE: The code inside isFinite does an assignment (=).
  return isFinite((a = convert(a).valueOf())) &&
    isFinite((b = convert(b).valueOf()))
    ? (a > b) - (a < b)
    : NaN;
};

export const somenteNumero = (valor) => {
  return valor.replace(/\D/g, "");
};

export const formatCpf = (cpf) => {
  const valor = cpf.replace(/\D/g, "");
  return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
};

export const formatCnpj = (cnpj) => {
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
};

export const geraCodigo = async (codigos) => {
  function randomString(length, chars) {
    var mask = "";
    if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
    if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    if (chars.indexOf("#") > -1)
      mask +=
        "012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789";
    if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
    var result = "";
    for (var i = length; i > 0; --i)
      result += mask[Math.floor(Math.random() * mask.length)];
    return result;
  }

  var gerado = randomString(6, "#A");

  var qtdGerado = 1;
  while (qtdGerado > 0) {
    gerado = randomString(6, "#A");
    qtdGerado = codigos.filter((x) => x.codigo === gerado).lenth;
  }

  return gerado;
};

export const formataMoeda = (e) => {
  e = e.replace(",", "");

  if (e.length < 3 && parseInt(e) > 0) {
    e = ("000" + e).substr(-3);
  } else if (e.replace(/^0+/, "").length > 2) {
    e = e.replace(/^0+/, "");
  } else if (e.replace(/^0+/, "").length === 2) {
    e = "0" + e.substr(-2);
  }

  if (parseInt(e) > 0) {
    var pt1 = e.substr(0, e.length - 2);
    var pt2 = e.substr(-2);
    return "R$ " + pt1 + "," + pt2;
  } else {
    return "";
  }
};
export const formataDecimal = (e) => {
  e = e.replace(",", "");

  if (e.length < 3 && parseInt(e) > 0) {
    e = ("000" + e).substr(-3);
  } else if (e.replace(/^0+/, "").length > 2) {
    e = e.replace(/^0+/, "");
  } else if (e.replace(/^0+/, "").length === 2) {
    e = "0" + e.substr(-2);
  }

  if (parseInt(e) > 0) {
    var pt1 = e.substr(0, e.length - 2);
    var pt2 = e.substr(-2);
    return pt1 + "," + pt2;
  } else {
    return "";
  }
};

export function retiraAcentos(str) {
  var com_acento =
    "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

  var sem_acento =
    "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
  var novastr = "";
  for (var i = 0; i < str.length; i++) {
    var troca = false;
    for (var a = 0; a < com_acento.length; a++) {
      if (str.substr(i, 1) === com_acento.substr(a, 1)) {
        novastr += sem_acento.substr(a, 1);
        troca = true;
        break;
      }
    }
    if (troca === false) {
      novastr += str.substr(i, 1);
    }
  }
  return novastr.replace(/[^a-zA-Z0-9]/g, "");
}
